import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

//import Header from './header'
import '../index.css'

const Layout = ({ children, data }) => (
    <div className="flex flex-col font-roboto min-h-screen text-grey-darkest">
        <Helmet
            title="Marco Sehrer - Full Stack Consultant, Web / Mobile Developer and Audio Designer"
            meta={[
                {
                    name: "description",
                    content:
                        "Full Stack Consultant, Web / Mobile Developer and Audio Designer"
                },
                {
                    name: "keywords",
                    content: "code, web, mobile, app, apps, design, audio"
                },
                {
                    name: "google-site-verification",
                    content: "wKsFJdWakmxtP5Ub2iMh7oTUlIJus0xLWbDfuuWbQ1Y"
                }
            ]}
        />

        <div>
            {children}
        </div>
    </div>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
