import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import ReactNativeLove from "../images/reactNativeLove.png";
import ReactReduxGraphQL from "../images/react_redux_graphql.png";
import NodeRailsPhoenix from "../images/node_js_ruby_on_rails_elixir_phoenix.png";
import Love from "../images/reactNativeLove.png";
import MacPadPhone from "../images/Macbook__ipad__iphone.png";
import AudioWave from "../images/waveform.png";
import ScrollDown from "../images/scroll_down.png";

const IndexPage = () => (
    <Layout>
        <div className="flex flex-col items-center font-roboto">
            <div className="bg-mood-image bg-greyish-brown bg-no-repeat bg-top bg-cover w-full min-h-screen justify-center flex flex-col">
                <div className="container mx-auto p-4 md:p-8 flex flex-col">
                    <div className="flex flex-col justify-center text-shadow">
                        <p className="text-3xl text-white flex flex-row items-center mb-2">
                            Marco Sehrer
          </p>
                        <h1 className="text-4xl md:text-5xl uppercase tracking-tight text-white">
                            Full Stack Consultant,<br />Web / Mobile Developer<br />& Audio
                            Designer
          </h1>
                        <h2 className="text-2xl md:text-3xl mt-2 font-light text-white leading-tight uppercase">
                            #Code #Web #Mobile #Apps #Design #Audio
          </h2>
                    </div>
                    {/* <div className="h-4 md:h-32 mt-8">
          <div className="max-w-sm md:w-1/2">
            <a
              class="mailto"
              href="mailto:ms@ninjaconcept.com"
              className="no-underline"
            >
              <div className="btn-primary text-center uppercase text-xl md:text-2xl inline">
                available to hire
              </div>
            </a>
          </div>
        </div> */}
                </div>
                <div className="h-16" />
                <div className="absolute pin-b items-center w-full flex flex-col">
                    <img src={ScrollDown} className="mb-4" />
                </div>
            </div>
            <div className="container mx-auto p-4 md:p-8 flex flex-wrap items-center">
                <div className="w-full flex items-center justify-center py-8">
                    <h2 className="text-2xl md:text-3xl font-light leading-tight uppercase">
                        Full Stack Development
        </h2>
                </div>
                <div className="service-section">
                    <div className="service-section-image-wrapper">
                        <img src={ReactReduxGraphQL} className="service-section-image" />
                    </div>
                    <h3 className="service-section-headline">Frontend Development</h3>
                    <div className="service-section-teaser">
                        <span className="text-bright-light-blue">React</span>
                        ,
          <span className="text-bluey-purple"> Redux</span>
                        ,
          <span className="text-barbie-pink"> GraphQL </span>
                        - the most popular Frontend Stack.
        </div>
                </div>

                <div className="spacer-bar md:hidden" />

                <div className="service-section">
                    <div className="service-section-image-wrapper">
                        <img src={NodeRailsPhoenix} className="service-section-image" />
                    </div>
                    <h3 className="service-section-headline">Backend Development</h3>
                    <div className="service-section-teaser">
                        NodeJS, Ruby/Rails & Elixir/Phoenix - choose your framework.
        </div>
                </div>

                <div className="spacer-bar" />

                <div className="service-section">
                    <div className="service-section-image-wrapper">
                        <img src={ReactNativeLove} className="service-section-image" />
                    </div>
                    <h3 className="service-section-headline">Native Mobile Development</h3>
                    <div className="service-section-teaser">
                        React Native is the future of hybrid app development.
        </div>
                </div>

                <div className="spacer-bar md:hidden" />

                <div className="service-section">
                    <div className="service-section-image-wrapper">
                        <img src={MacPadPhone} className="service-section-image h-32" />
                    </div>
                    <h3 className="service-section-headline">UI/UX Design</h3>
                    <div className="service-section-teaser">
                        Everything starts with great UI/UX!
        </div>
                </div>
            </div>

            <div className="w-full bg-greyish-brown  ">
                <div className="container mx-auto service-section">
                    <div className="service-section-image-wrapper">
                        <img src={AudioWave} className="service-section-image" />
                    </div>
                    <h3 className="service-section-headline text-white">
                        Audio Productions
        </h3>
                    <div className="service-section-teaser text-white">
                        Sound Design, Audio Logos, Game Sounds
        </div>
                </div>
            </div>
            <div className="w-full bg-grey-lightest w-full p-4 md:p-8 flex flex-col justify-center items-center h-64">
                <div className="mt-8 ">
                    <a
                        class="mailto"
                        href="mailto:info@marcosehrer.com"
                        className="no-underline"
                    >
                        <div className="btn-primary text-center uppercase text-xl no-underline">
                            contact me
          </div>
                    </a>
                </div>
            </div>
            <div className="w-full h-48 flex flex-col bg-bright-light-blue leading-normal text-white">
                <div className="container mx-auto p-4 md:p-8">
                    <p>
                        Marco Sehrer<br />
                        Karlsruher Weg 26<br />
                        76185 Karlsruhe<br />
                        Umsatzsteuer ID: DE813918576
        </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
